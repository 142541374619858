import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ConsentimientoLegal from '../../components/consentimientoLegal/ConsentimientoLegal'
import { fetchLegal, fetchLegalSuccess, terminosAceptados, openYesNoModal } from '../../actions/consentimientoLegal/consentimientoLegal'

export function mapStateToProps (state) {
  return {
    ...state.consentimientoLegal,
    auth: state.auth,
    isVisible: state.consentimientoLegal.isVisible,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      terminosAceptados, fetchLegal, fetchLegalSuccess, openYesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConsentimientoLegal))