import actionTypes from '../../constants/actions/consentimientoLegal/consentimientoLegal'

export function terminosAceptados () {
  return {
    type: actionTypes.TERMINOS_ACEPTADOS
  }
}

export function fetchLegal () {
  return {
    type: actionTypes.FETCH_LEGAL
  }
}

export function openYesNoModal () {
  console.log("EVENTO MOSTRAR MODAL |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
  return{
    type: actionTypes.OPEN_YESNO_MODAL
  }
}

export function fetchLegalSuccess (legal) {
  return {
    type: actionTypes.FETCH_LEGAL_SUCCESS,
    legal
  }
}

export default {
  terminosAceptados,
  fetchLegal,
  fetchLegalSuccess,
  openYesNoModal,
}