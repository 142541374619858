import React, { PureComponent } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import './layout.scss'
import HeaderPage from '../../containers/layout/HeaderPage'
import FooterPage from '../../containers/layout/FooterPage'
import SidebarPage from '../../containers/layout/SidebarPage'
import MenuHorizontalPage from '../../containers/layout/MenuHorizontalPage'
import ConsentimientoLegalPage from '../../containers/consentimientoLegal/ConsentimientoLegalPage'
import {throttle} from '../../util/util'

const getAppMeasure = (e) => {
  let width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  let height = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  return {width, height}
}

class PrivateLayout extends PureComponent {
  constructor() {
    super()
    this.state = getAppMeasure()
    this.updateDimensions = this.updateDimensions.bind(this)
  }
  updateDimensions() {
    throttle(this.setState(getAppMeasure()), 200)
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render () {
    return (
      <Grid fluid className="private-layout">
        <Row>
          <HeaderPage sm={12} isUser={true} />
        </Row>
          {this.state.width > 1000 ? (
            <div className="menu-desktop">
              <MenuHorizontalPage sm={12} />
            </div>
          ) : (
            <div className="menu-responsive">
              <SidebarPage sm={2}/>
            </div>
          )}
          <Col sm={12} className="main-container">
            {this.props.children}
          </Col>
        <Row>
          <FooterPage sm={12} />
        </Row>
        <Row>
          <Col sm={12}>
            <ConsentimientoLegalPage/>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default PrivateLayout
