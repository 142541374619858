import {call, select, put, takeLatest} from 'redux-saga/effects'
import actionTypes from '../../constants/actions/consentimientoLegal/consentimientoLegal'
import aceptarConsentimiento from '../../services/empresa/aceptarConsentimiento'
import getConsentimiento from '../../services/empresa/getConsentimiento'
import { fetchLegalSuccess} from '../../actions/consentimientoLegal/consentimientoLegal'
import { yesNoModal as yesNoModalSaga } from '../modal/yesNoModal'

export function * watchAcepetarTerminos () {
  yield takeLatest(actionTypes.TERMINOS_ACEPTADOS, aceptarTerminos)
}
export function * aceptarTerminos () {
  let state = yield select(state => state)
  try {
    yield call(aceptarConsentimiento, state.auth.token)
    yield call(fetchLegal)
  } catch (error) {
  }
}
  

export function * watchFetchLegal () {
  yield takeLatest(actionTypes.FETCH_LEGAL, fetchLegal)
}
export function * fetchLegal () {
  try {
    const legal = yield call(getConsentimiento)
    if(legal.legal != undefined)yield put(fetchLegalSuccess(legal.legal))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchOpenYesNoModal () {
  yield takeLatest(actionTypes.OPEN_YESNO_MODAL, openYesNo)
}
export function * openYesNo () {
  console.log("SAGAAAA ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
  const confirmed = yield call(yesNoModalSaga, { modalType: 'InfoLegal' })
  if (confirmed) {
    yield call(aceptarTerminos)
  }
}