import React, { Component } from 'react'
import { reduxForm } from 'redux-form'
import './ConsentimientoLegal.scss';

class ConsentimientoLegal extends Component {

  componentDidMount(){
    this.props.actions.fetchLegal();
  }

  aceptarTerminos(){
    this.props.actions.terminosAceptados();
  }

  mostrarModal(){
    console.log("EVENTO MOSTRAR MODAL |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
    this.props.actions.openYesNoModal();
  }

  render(){
    const {
      t
    } = this.props
    const tKey = 'CONSENTIMIENTO_LEGAL.'
  return (
    <>
      {this.props.isVisible && (
        <div className="consent-banner">
          <p className="consent-text">
            {t(tKey + "BANNER_BODY")}
          </p>
          <div className="button-container">
            <button className="modal-button" onClick={() => this.mostrarModal()}>{t("COMUN.VER")}</button>
            <button className="consent-button" onClick={() => this.aceptarTerminos()}>{t("SIMPLE_MODAL.ACEPTAR")}</button>
          </div>
        </div>
      )}
    </>
    );
  }
}

export default (reduxForm({
  form: 'formConsentimientoLegal',
})(ConsentimientoLegal));