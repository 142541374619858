import actionTypes from '../../constants/actions/consentimientoLegal/consentimientoLegal'

export function initialState () {
  return {
    isVisible: false,
  }
}

export function fetchLegalSuccess (state, legal) {
  return {
    ...state,
    isVisible: !legal.legal
  }
}

export default function (state = initialState(), action) {
    switch (action.type) {
      case actionTypes.FETCH_LEGAL_SUCCESS:
        return fetchLegalSuccess(state, action)
      default:
        return state
    }
  }
 